var SJ = SJ || {};
var __ = __ || function(str) { return str; };

SJ.SitesFrontend = SJ.SitesFrontend || {

    cookieContent : null,
    ga_enabled : 'ga_enabled',
    analiticsCookieName : 'analitics_enabled',
    checkboxCookies : null,
    analyticsCookiesNames: ["_ga", "_gid", "_gali", "_gat", "__utma", "__utmt", "__utmb", "__utmc", "__utmz", "__utmv"],

    init : function() {
        // Comments functions
        $(document).on('click', '.sj_show_more_comments', function(e) {
            var element_hash = $(this).closest('.element').find('input[name=hash]').val(),
                offset = $(this).attr('data-offset'),
                order = $(this).attr('data-order');

            $.getJSON('/comments/more/'+offset+'/'+element_hash+'/'+order, function(data) {
                if (data.status == 1) {
                    var $element = $('input[name=hash][value='+data.hash+']').closest('.element').find('.sj_comments_initial');
                    $element.find('.sj_show_more_comments').remove();
                    $element.append(data.html);
                }
            });

            e.preventDefault();
        });

        $('.sj_show_comments').click(function(e) {
        var comments =  $(this).parent('.sj_comments').find('.sj_comments_initial');
            comments.toggle();
            if (comments.is(":visible")) {
                $(this).text($(this).attr('data-hide-text'));
            } else {
                $(this).text($(this).attr('data-show-text'));
            }

            e.preventDefault();
        });

        SJ.SitesFrontend.cookieContent = $('#ccc');
        SJ.SitesFrontend.checkboxCookies = $('#analytics_cookies');
    },

    dismissElement : function () {
        if( SJ.SitesFrontend.cookieContent.attr('open')==='open' )
        {
            if( typeof $.cookie( SJ.SitesFrontend.analiticsCookieName ) === 'undefined' )
            {
                SJ.SitesFrontend.saveAnaliticsCookieSettings();
            }

            SJ.SitesFrontend.cookieContent.removeAttr('open');
        } else {
            SJ.SitesFrontend.cookieContent.attr('open', true);
        }
    },

    cookieControl: function ()
    {
        if ( typeof $.cookie( SJ.SitesFrontend.analiticsCookieName ) === 'undefined' ) {
            SJ.SitesFrontend.cookieContent.attr('open', true);
        } else if ( $.cookie( SJ.SitesFrontend.analiticsCookieName ) === '1' ) {
            SJ.SitesFrontend.checkboxCookies.prop('checked', true);
        }

        $('#ccc-icon').click(function ()
        {
            SJ.SitesFrontend.cookieContent.attr('open', true);
        });

        $('#ccc-dismiss-button').click(function ()
        {
            SJ.SitesFrontend.dismissElement();
        });

        $("#ccc-recommended-settings").click(function () {

            SJ.SitesFrontend.checkboxCookies.prop('checked', true);
            SJ.SitesFrontend.saveAnaliticsCookieSettings();
            $.cookie(SJ.SitesFrontend.ga_enabled, 'true', { domain: '.'+document.location.hostname, expires: 365, path: '/' });

            SJ.SitesFrontend.dismissElement();
        });

        $("#ccc-reject-settings").click(function () {

            SJ.SitesFrontend.checkboxCookies.prop('checked', false);
            SJ.SitesFrontend.saveAnaliticsCookieSettings();
            SJ.SitesFrontend.removeAnalyticsCookies();
            $.removeCookie(SJ.SitesFrontend.ga_enabled, {domain: '.'+document.location.hostname, path: '/'});

            SJ.SitesFrontend.dismissElement();
        });

        SJ.SitesFrontend.checkboxCookies.change(function ()
        {
            SJ.SitesFrontend.saveAnaliticsCookieSettings();
            if( SJ.SitesFrontend.checkboxCookies.prop('checked') === false )
            {
                SJ.SitesFrontend.removeAnalyticsCookies();
            }
        });
    },

    saveAnaliticsCookieSettings: function ()
    {
        if( SJ.SitesFrontend.checkboxCookies.prop('checked') )
        {
            window[disableStr] = false;
            $.cookie(SJ.SitesFrontend.analiticsCookieName, 1, { domain: '.'+document.location.hostname, expires: 365, path: '/' });
        }
        else
        {
            window[disableStr] = true;
            $.cookie(SJ.SitesFrontend.analiticsCookieName, 0, { domain: '.'+document.location.hostname, expires: 365, path: '/' });
        }
    },

    removeAnalyticsCookies: function()
    {


        $.map(SJ.SitesFrontend.analyticsCookiesNames, function(cookieName) {
            $.removeCookie(cookieName, {domain: '.'+document.location.hostname, path: '/'});
        });
    }
};

// Init all needed references
$(document).ready(function() {

    SJ.SitesFrontend.init();
    if( typeof disableStr !== 'undefined' )
    {
        new SJ.SitesFrontend.cookieControl();
    }
});
